var render = function render(){var _vm=this,_c=_vm._self._c;return _c('FadeTransition',{attrs:{"duration":200,"origin":"center top"}},[_c('section',{staticClass:"login"},[_c('section',{staticClass:"inicia-sesion"},[_c('h1',{staticClass:"title text-center my-5"},[_vm._v("Inicia sesión")]),_c('div',{staticClass:"card"},[_c('DataInput',{attrs:{"label":"Correo","type":"email","model":_vm.model.email,"error":_vm.error == 'empty_email',"required":""},on:{"newValue":function($event){_vm.model.email = $event}}}),_c('DataInput',{attrs:{"label":"Contraseña","type":"password","model":_vm.model.password,"error":_vm.error == 'empty_pass',"required":""},on:{"newValue":function($event){_vm.model.password = $event},"sendEnterEvent":_vm.login}}),_c('p',{staticClass:"text-center mb-4"},[_vm._v(" ¿Olvidaste tu contraseña?"),_c('span',{staticClass:"ml-2 link click",on:{"click":function($event){_vm.show = true}}},[_vm._v("Recuperar")])]),_c('button',{staticClass:"btn btn-base w-100 second lg my-4",attrs:{"disabled":_vm.loading},on:{"click":_vm.login}},[_vm._v(" Iniciar sesión ")]),_c('v-facebook-login',{staticClass:"mb-3 fb-login",staticStyle:{"margin":"auto"},attrs:{"login-options":{
            scope: 'pages_show_list,business_management,catalog_management,instagram_manage_messages,pages_messaging,instagram_manage_insights,instagram_basic,pages_manage_metadata,pages_read_engagement,instagram_manage_comments',
          },"app-id":"280308108047238"},on:{"login":_vm.facebookLogged}}),_c('div',{staticClass:"mb-4"},[_c('p',{staticClass:"text-center"},[_vm._v(" ¿No tienes cuenta? "),_c('router-link',{staticClass:"link ml-1",attrs:{"to":{
                path: '/registro',
                query: {
                  shop: _vm.$route.query.shop,
                  access_token: _vm.$route.query.access_token,
                  token_access: _vm.$route.query.access_token,
                  shop_domain: _vm.$route.query.shop_domain || _vm.platform_name,
                  code: _vm.$route.query.code,
                },
              }}},[_vm._v("Regístrate ->")])],1)]),_c('PassRecoverModal',{attrs:{"showModal":_vm.show,"mail_to_recover":_vm.mail_to_recover,"error":_vm.error == 'empty_mail'},on:{"closeModal":function($event){_vm.show = false},"passRecover":function($event){(_vm.mail_to_recover = $event), _vm.passRecover()}}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }