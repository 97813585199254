<template>
  <section class="inicia-sesion">
    <h1 class="title text-center my-5">Inicia sesión</h1>
    <div class="card">
      <DataInput
        label="Correo"
        type="email"
        :model="model.email"
        :error="error == 'empty_email'"
        @newValue="model.email = $event"
        required
      />
      <DataInput
        label="Contraseña"
        type="password"
        :model="model.password"
        :error="error == 'empty_pass'"
        @newValue="model.password = $event"
        @sendEnterEvent="login"
        required
      />
      <p class="text-center mb-4">¿Olvidaste tu contraseña?<span class="ml-2 link click" @click="show = true">Recuperar</span></p>
      <button :disabled="loading" @click="login" class="btn btn-base w-100 second lg my-4">
        Iniciar sesión
      </button>
      <div class="mb-4">
        <p class="text-center">¿No tienes cuenta?
          <router-link
            class="link ml-1"
            :to="{
              path: '/registro',
              query: {
                shop: $route.query.shop,
                access_token: $route.query.access_token,
                token_access: $route.query.access_token,
                shop_domain: $route.query.shop_domain || platform_name,
                code: $route.query.code
              }
            }"
          >Regístrate -></router-link>
        </p>
      </div>
      <PassRecoverModal
        :showModal="show"
        :mail_to_recover="mail_to_recover"
        :error="error == 'empty_mail'"
        @closeModal="show = false"
        @passRecover="mail_to_recover = $event, passRecover()"
      />
    </div>
  </section>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import dashboard_api_login from "@/dashboard_api.js";
// import dashboard_api_login from "@/dashboard_api_login.js";
import DataInput from "@/components/builders/DataInput";
import PassRecoverModal from "@/views/V2/Auth/components/PassRecoverModal";
import mobile from "is-mobile";
import axios from "axios";
import socket from '@/plugins/sockets';
import environment from "../../../../environment";

export default {
  name: "FormLogin",

  components: {
    DataInput,
    PassRecoverModal
  },
  data() {
    return {
      mail_to_recover: "",
      model: {
        email: "",
        password: ""
      },
      error: "",
      loading: false,
      show: false
    };
  },

  computed: {
    ...mapState(["platform_name"]),

    loginq() {
      return this.$route.query.login;
    },
    bot_id() {
      return this.$route.query.bot_id;
    }
  },
  methods: {
    ...mapMutations(["loginAction", "setAgentStatus", "setUserBot"]),

    async login() {
      const self = this;
      const { email, password } = self.model;

      if (email.length == 0) self.error = "empty_email";
      else if (password.length == 0) self.error = "empty_pass";
      else {
        self.error = "";
        self.loading = true;

        self.$swal({
          icon: "info",
          title: "Iniciando...",
          showConfirmButton: false
        });
        self.$swal.showLoading();
        dashboard_api_login
          .post("/auth/sign_in", {
            email: self.model.email,
            password: self.model.password
          })
          .then(result => {
            if (result.data.data.is_active == false) {
              self.loading = false;
              self.$swal({
                icon: "warning",
                title: "Tu cuenta está desactivada. Por favor contacta a soporte.",
                showConfirmButton: false
              });
              return;
            }
            self.$store.commit("authModule/credentialsStorage", {
              ...result.headers
            });
            if (result.data.data) {
              dashboard_api_login.post("/user_logins", {
                user_login: {
                  user_id: result.data.data.id,
                  device: mobile() ? "mobile" : "desktop"
                }
              }).then(() => console.log("user_logins saved"));
            }
            dashboard_api_login
              .post("/bot/list_bot_ids_by_user", { user_id: result.data.data.id })
              .then(botsResponse => {
                dashboard_api_login
                  .get(`/client_facturacion_data/by_user_id/${result.data.data.id}`)
                  .then(async clientDataResponse => {
                    if (clientDataResponse.data != null) {
                      await this.activateAgentStatus(result.data.data.id, botsResponse.data)
                      self.loginAction({
                        user: result.data.data,
                        customerId: clientDataResponse.data.costumer_id,
                        token: result.headers["access-token"],
                        botIds: botsResponse.data
                      });
                      self.$swal({
                        icon: "success",
                        title: "Has iniciado sesión exitosamente",
                        timer: 1000,
                        timerProgressBar: false,
                        showConfirmButton: false
                      });
                      // La ruta donde se dirigira el usuario logeado, variara con su plan
                      if (!self.loginq) {
                        if (self.platform_name == "jumpseller") {
                          self.$router.push({
                            name: "integration-install",
                            query: {
                              token_access: self.$route.query.token_access,
                              platform: self.platform_name,
                              shop_domain: self.$route.query.shop_domain
                            }
                          });
                        } else {
                          self.$router.push("/dashboard");
                        }
                      } else {
                        self.$router.push({
                          name: "register",
                          query: { activar: "activate", bot_id: self.bot_id }
                        });
                      }
                    } else {
                      dashboard_api_login
                        .post("/flow_requests/create_customer", {
                          flow_request: {
                            name: result.data.data.name,
                            email: result.data.data.email,
                            externalId: result.data.data.id
                            // externalId: `${result.data.data.email}_${result.data.data.id}`
                          }
                        })
                        .then(async flowCustomerDataCreateResponse => {
                          await this.activateAgentStatus(result.data.data.id, botsResponse.data)
                          self.loginAction({
                            user: result.data.data,
                            customerId: flowCustomerDataCreateResponse.data.costumer_id,
                            token: result.headers["access-token"],
                            botIds: botsResponse.data
                          });

                          self.$swal({
                            icon: "success",
                            title: "Has iniciado sesión exitosamente",
                            timer: 1000,
                            timerProgressBar: false,
                            showConfirmButton: false
                          });
                          // La ruta donde se dirigira el usuario logeado, variara con su plan
                          if (!self.loginq) {
                            if (self.platform_name == "jumpseller") {
                              self.$router.push({
                                name: "integration-install",
                                query: {
                                  token_access: self.$route.query.token_access,
                                  platform: self.platform_name,
                                  shop_domain: self.$route.query.shop_domain
                                }
                              });
                            } else {
                              console.log("botsResponse->", botsResponse.data);
                              self.$router.push("/dashboard");
                            }
                          } else {
                            self.$router.push({
                              name: "register",
                              query: { activar: "activate", bot_id: self.bot_id }
                            });
                          }
                        })
                        .catch(error => console.error("error ->", error));
                    }
                    self.loading = false;
                  })
                  .catch(error => console.error("error ->", error));
                // self.loginAction({ user: result.data.data, token: result.headers["access-token"] });
              });
          })
          .catch(error => {
            self.loading = false;
            self.$swal({
              icon: "error",
              title: "Error al loguearse",
              text: "Su correo o contraseña son incorrectos"
            });
          });
      };
    },
    // FUNCION PARA PASAR A ESTADO ACTIVO AL LOGEARSE
    async activateAgentStatus(userId, botIds) {
      try {
        const userBot = []
        const user = (await dashboard_api_login.post(`/userbot/find_user_bot_with_permissions/${userId}`, { bot_ids: botIds })).data
        user.map(e => userBot.push({ bot_id: e.bot_id, user_bot_id: e.id }))
        await dashboard_api_login.put(`/userbot/userbotedit/${user[0].id}`, {
          "agent_status": "active"
        })
        socket.emit('AGENT_STATUS', {
          botId: botIds[0],
          agent_id: Number(userId),
          status: "active",
        });
        if(user[0].role.name != 'propietario') {
          await axios.put(`${environment.url_redis}/update_status_counter_for_agent`,{
            "agent_id": Number(user[0].user_id),
            "status": 'active',
            "bot_id": user[0].bot_id
          })
        }
        this.setAgentStatus("active")
        this.setUserBot(userBot)
      } catch (error) {
        console.log(error);
      }
    },
    passRecover() {
      const self = this;

      if (self.mail_to_recover === "") {
        self.error = "empty_mail";
      } else {
        self.$swal({
          icon: "info",
          title: "Enviando...",
          showConfirmButton: false
        });
        self.$swal.showLoading();

        dashboard_api_login
          .post("/password/forgot", {
            email: self.mail_to_recover
          })
          .then(response => {
            if (response.data.status == "ok") {
              self.$swal({
                icon: "success",
                title: "Correo de recuperación enviado",
                text: "Si no ves el correo, recuerda revisar tu carpeta de spam"
              });
              self.error = "";
              self.show = false;
            }
          })
          .catch(err => {
            console.log(err);
            self.$swal({
              icon: "error",
              title: "Error al enviar el correo"
            });
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.inicia-sesion {
  width: 100%;

  @media (max-width: 480px) {
    margin: 2rem auto;
  }

  .title {
    font-size: 35px;
    margin-bottom: 0;

    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }

  .card {
    margin: auto;
    border: none;
    background-color: transparent;
    max-width: 450px;
    width: 100%;
    z-index: 3;

    @media (max-width: 640px) {
      padding-inline: 1.5rem;
    }
  }

  .btn-base {
    font-size: 1rem;
  }
}
</style>
